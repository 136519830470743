import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import LoaderComponent from "../../Loader/Loader";
import { toast } from "react-hot-toast";
import { getUserBalance } from "../investments/GetUserBalance";

const Transactions = ({ darkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchBalance = async () => {
      const userBalance = await getUserBalance();
      if (userBalance !== null) {
        console.log("User balance:", userBalance);
      }
    };

    fetchBalance();
  }, []);

  // Get page from URL or default to 1
  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);

  // Update URL when page changes
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`/transactions?page=${newPage}&limit=10`);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error("You need to be logged in to view transactions");
          return;
        }

        const response = await fetch(
          `https://getusertransactions-qj6yfdqbiq-uc.a.run.app?page=${currentPage}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setTransactions(data.transactions);
        setTotalPages(data.pagination.totalPages);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const formatDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  const formatAmount = (amount, type) => {
    const formattedAmount = Math.round(Math.abs(amount));
    const sign = type.toLowerCase() === "deposit" || type.toLowerCase() === "earning" ? "+" : "-";
    return `${sign} $${formattedAmount}`;
  };

  return (
    <>
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Transactions"
        subTitle="your"
        variant="text-left"
        href="/transactions"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="4"
                        rowSpan="10"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "570px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoaderComponent />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    transactions.map((transaction, index) => (
                      <tr
                        key={transaction.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "transparent" : "#242424",
                        }}
                      >
                        <td>{transaction.id}</td>
                        <td>{formatDate(transaction.timestamp)}</td>
                        <td>{transaction.type}</td>
                        <td
                          style={{
                            color:
                              transaction.type.toLowerCase() === "deposit"
                                ? "#009A00"
                                : transaction.type.toLowerCase() === "withdraw"
                                ? "#E40000"
                                : transaction.type.toLowerCase() === "spent"
                                ? "#14FFFF"
                                : transaction.type.toLowerCase() === "earning"
                                ? "#00FF00"
                                : "inherit",
                            fontWeight: "bold",
                          }}
                        >
                          {formatAmount(transaction.amount, transaction.type)}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div
              className="cs_pagination"
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                Next
              </button>
            </div>

            <div className="cs_height_30 cs_height_lg_30" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;