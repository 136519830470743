import styled from "styled-components";

export const Container = styled.div`
  margin-top: 2rem;
`;

export const FormContainer = styled.div`
  background: #222221;
  padding: 1.5rem;
  border-radius: 4px;
  color: #000;
`;

export const FormTitle = styled.h4`
  margin-bottom: 1.5rem;
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  display: block;
  color: #fff;
  margin-bottom: 0.5rem;
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InputPrefix = styled.span`
  padding: 0.375rem 0.75rem;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 4px 0 0 4px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: ${(props) => (props.hasPrefix ? "0 4px 4px 0" : "4px")};
  color: #000 !important;

  &:read-only {
    background-color: #e9ecef;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

export const InputNumberShares = styled(Input)`
  height: 38px;
  border-radius: 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

export const QuantityButton = styled.button`
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.375rem 0.75rem;
  cursor: pointer;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  background: ${(props) => (props.variant === "buy" ? "#11C160" : "#C5171A")};
`;

export const TermsLink = styled.span`
  color: #2c65ff;
  cursor: pointer;
`;
