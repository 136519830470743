import React from "react";
import Spacing from "../Spacing";
import HeroStyle2 from "../Hero/HeroStyle2";
import Brands from "../Brands";
import Marquee from "../Marquee";
import Cta from "../Cta";
import SectionHeading from "../SectionHeading";
import Accordion from "../Accordion";
import PostCarousel from "../Slider/PostCarousel";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Award from "../Award";
import VideoModal from "../VideoModal";
import ServiceSlider from "../Slider/ServiceSlider";
import AboutStyle2 from "../About/AboutStyle2";
import FunFact from "../FunFact";
import Button from "../Button";
import CaseStudy from "../CaseStudy";
import { pageTitle } from "../../helpers/PageTitle";
const servideData = [
  {
    iconSrc: "/section 2/img1.png",
    title: "Solar Farm",
    subTitle:
      "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
  {
    iconSrc: "/section 2/img2.png",
    title: "Edf. AirBnb",
    subTitle:
      "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
  {
    iconSrc: "/section 2/img3.png",
    title: "Facit",
    subTitle:
      "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
  {
    iconSrc: "/section 2/img4.png",
    title: "Big Data Inc.",
    subTitle:
      "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
  // {
  //   iconSrc: "/images/marketing-agency/service_icon_1.svg",
  //   title: "On page optimization",
  //   subTitle:
  //     "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
  //   btnText: "See More",
  //   btnUrl: "/service/service-details",
  // },
  // {
  //   iconSrc: "/images/marketing-agency/service_icon_2.svg",
  //   title: "Marketing Consulting",
  //   subTitle:
  //     "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
  //   btnText: "See More",
  //   btnUrl: "/service/service-details",
  // },
  // {
  //   iconSrc: "/images/marketing-agency/service_icon_3.svg",
  //   title: "Video Campaign",
  //   subTitle:
  //     "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
  //   btnText: "See More",
  //   btnUrl: "/service/service-details",
  // },
  // {
  //   iconSrc: "/images/marketing-agency/service_icon_4.svg",
  //   title: "Branding Design",
  //   subTitle:
  //     "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
  //   btnText: "See More",
  //   btnUrl: "/service/service-details",
  // },
];
const funfactData = [
  { title: "Invested", number: "372M" },
  { title: "Projects Launched", number: "65" },
  { title: "Investors", number: "1k" },
  { title: "Yearly Return", number: "+24%" },
];
const awardData = [
  {
    brand: "1",
    title: "UI/UX design of the month",
    subTitle:
      "Investments through the platform bring some of the fastest returns in the segment. At Barzel, there have already been 3: Alter, Wuzu, and Finansystech.",
    date: "December 12, 2023",
    awardImgUrl: "/images/creative-agency/award_img_1.svg",
  },
  {
    brand: "2",
    title: "CSS awards design",
    subTitle: "Support entrepreneurship, generate jobs, and boost the economy.",
    date: "January 05, 2022",
    awardImgUrl: "/images/creative-agency/award_img_2.svg",
  },
  {
    brand: "3",
    title: "Website of the day",
    subTitle:
      "Exchange experiences: teach and learn with entrepreneurs and our investor community.",
    date: "March 20, 2021",
    awardImgUrl: "/images/creative-agency/award_img_3.svg",
  },
];
const testimonialData = [
  {
    text: "I'm impressed with Barzel's returns - my investments have consistently yielded 20-25% annually. The platform makes it easy to track performance and reinvest profits. Best investment decision I've made!",
    avatarName: "Daniel Goldfarb",
    avatarDesignation: "Surgeon",
  },
  {
    text: "Barzel is fantastic! Simple, transparent platform with great support. I was able to finance my project easily. Highly recommend!",
    avatarName: "Gustavo Albuquerque",
    avatarDesignation: "CEO at Bosforo",
  },
  {
    text: "Barzel exceeds expectations! Their straightforward, transparent platform and outstanding support made financing my project a breeze. I can't recommend it enough!",
    avatarName: "Avraham Goldstein",
    avatarDesignation: "CEO at Delta",
  },
];
const caseStudyData = [
  {
    thumbnailSrc: "/images/marketing-agency/case_study_1.jpeg",
    title: "Digital marketing management",
    category: "Marketing",
    number: "01",
    href: "/case-study-details",
  },
  {
    thumbnailSrc: "/images/marketing-agency/case_study_2.jpeg",
    title: "Digital marketing management",
    category: "Marketing",
    number: "02",
    href: "/case-study-details",
  },
  {
    thumbnailSrc: "/images/marketing-agency/case_study_3.jpeg",
    title: "Digital marketing management",
    category: "Marketing",
    number: "03",
    href: "/case-study-details",
  },
  {
    thumbnailSrc: "/images/marketing-agency/case_study_4.jpeg",
    title: "Digital marketing management",
    category: "Marketing",
    number: "04",
    href: "/case-study-details",
  },
];
const postData = [
  {
    thumbnailSrc: "/section 7/img1.png",
    title: "How to keep fear from ruining your art business with confident",
    date: "07 Mar 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img2.png",
    title: "Artistic mind will be great for creation anything",
    date: "22 Apr 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img3.png",
    title: "AI will take over all job for human within few years",
    date: "13 May 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img4.png",
    title: "Your agency need to replace some artistic mind people",
    date: "15 Mar 2023",
    url: "/blog/blog-details",
  },
  // {
  //   thumbnailSrc: "/images/creative-agency/post_1.jpeg",
  //   title: "How to keep fear from ruining your art business with confident",
  //   date: "07 Mar 2023",
  //   url: "/blog/blog-details",
  // },
  // {
  //   thumbnailSrc: "/images/creative-agency/post_2.jpeg",
  //   title: "Artistic mind will be great for creation anything",
  //   date: "22 Apr 2023",
  //   url: "/blog/blog-details",
  // },
  // {
  //   thumbnailSrc: "/images/creative-agency/post_3.jpeg",
  //   title: "AI will take over all job for human within few years",
  //   date: "13 May 2023",
  //   url: "/blog/blog-details",
  // },
  // {
  //   thumbnailSrc: "/images/creative-agency/post_4.jpeg",
  //   title: "Your agency need to replace some artistic mind people",
  //   date: "15 Mar 2023",
  //   url: "/blog/blog-details",
  // },
];
const faqData = [
  {
    title: "01. What is the legal model of investment?",
    content:
      "According to Brazilian law CVM 88, our investment platform operates under the crowdfunding model for investment in startups and small businesses. This regulation allows for the public offering of securities by small businesses through electronic platforms, providing a legal framework for retail investors to participate in early-stage investments.",
  },
  {
    title: "02. What are the risks of investing through Barzel?",
    content:
      "As with any investment, there are risks involved. Investing in startups and small businesses through our platform carries a high risk of partial or total loss of capital. The securities offered are not guaranteed by the Credit Guarantee Fund (FGC) or any other insurance mechanism. It's important to diversify your investments and only invest money you can afford to lose.",
  },
  {
    title: "03. What are the advantages of investing through Barzel?",
    content:
      "Investing through Barzel offers several advantages: access to a curated selection of startups and small businesses, the ability to invest with smaller amounts compared to traditional venture capital, potential for high returns, portfolio diversification, and the opportunity to support innovative companies. Our platform also provides transparency and detailed information about each investment opportunity, as required by CVM 88.",
  },
  {
    title: "04. Is the investment process entirely online?",
    content:
      "Yes, the entire investment process is conducted online through our platform. This includes browsing investment opportunities, conducting due diligence, making investment decisions, and managing your portfolio. However, as per CVM 88 regulations, certain documents and confirmations may need to be provided electronically to comply with investor verification requirements.",
  },
  {
    title: "05. Will I become a shareholder of the company?",
    content:
      "The type of security you receive depends on the specific offering. In many cases, you will receive convertible notes or other forms of securities that may convert to equity under certain conditions. Some offerings may provide direct equity. It's crucial to carefully read the terms of each investment opportunity. Remember, as per CVM 88, your rights as an investor, including voting rights and economic rights, will be clearly outlined in the offering documents.",
  },
];
const brandList = [
  {
    logoSrc: "/images/marketing-agency/brand_1.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_2.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_3.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_4.svg",
    logoAlt: "Brand",
  },
];
const brandListDark = [
  {
    logoSrc: "/images/marketing-agency/brand_1_dark.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_2_dark.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_3_dark.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_4_dark.svg",
    logoAlt: "Brand",
  },
];

export default function MarketingAgencyPage({ darkMode }) {
  pageTitle("Início");
  return (
    <>
      <HeroStyle2
        miniTitle="Tokenized Investments Platform"
        title="Make Big Investments"
        subTitle="Transform your investment potential with our tokenized platform - access fractional ownership in premium startups, real estate, and VC funds previously reserved for institutional investors."
        thumbnailSrc="/img1.png"
        mikeIcon={
          darkMode ? "/images/icons/mike_dark.svg" : "/images/icons/mike.svg"
        }
      />
      <section className="cs_p76_full_width" id="service">
        <Spacing lg="143" md="75" />
        <SectionHeading title="Projects" subTitle="Our" />
        <Spacing lg="85" md="45" />
        <ServiceSlider data={servideData} />
      </section>
      <section>
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          thumbnailSrc1="/section 3/img1.png"
          thumbnailSrc2="/section 3/img2.png"
          // uperTitle="Who We Are"
          title="Investing Made Simple"
          subTitle="Unlock premium investment opportunities in just 3 simple steps. Our tokenized platform lets you own shares in high-potential startups for as little as you want to invest. Zero fees, zero hassle - just pure investment potential with complete transparency and liquidity."
          featureList={[
            "Premium investment opportunities",
            "No fees and hassle",
            "Fully tokenized operation",
          ]}
          btnText="Learn more"
          btnUrl="/about"
        />
      </section>
      <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div>
      {/* <section>
        <Spacing lg="118" md="70" />
        <div className="container">
          <SectionHeading
            title="Real world solutions successful <br />case studies in Zivan"
            subTitle="Case Study"
          />
          <Spacing lg="85" md="45" />
        </div>
        <CaseStudy data={caseStudyData} />
        <Spacing lg="100" md="60" />
        <div className="container">
          <div className="text-center">
            <Button btnText="More Case Study" btnUrl="/" />
          </div>
        </div>
      </section> */}
      <div className="container">
        <Spacing lg="126" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/aSCoGaVvlMs?si=GfWzctiSzsEuHCS"
          bgUrl="/section 4/video.jpg"
          title="Discover new oportunities"
        />
      </div>
      <TestimonialSlider
        layeredImages={[
          "/section 5/img5.png",
          "/section 5/img1.png",
          "/section 5/img3.png",
          "/section 5/img4.png",
          "/section 5/img2.png",
        ]}
        data={testimonialData}
      />
      <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <SectionHeading
            title="Why invest?"
            subTitle="Discover"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Benefits of Investing with us"
            subTitle="Read more"
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently Asked Questions" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="60" />
      </section>
      <section>
        <div className="container">
          <Cta
            title="Ready to unlock the future of investing through tokenization?"
            btnText="Contact us"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      {/* <Spacing lg="135" md="70" />
      <Marquee text="We Create Design - Build App - Website - Branding - SEO" />
      <Spacing lg="84" md="50" />
      <div className="container">
        <Brands data={darkMode ? brandListDark : brandList} />
      </div>
      <Spacing lg="135" md="80" /> */}
    </>
  );
}
