import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";
import LoaderComponent from "../Loader/Loader";
import toast from "react-hot-toast";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 700px;
  width: 450px;
  background-color: #121212;
  z-index: 901;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;

  @media (max-width: 500px) {
    height: 600px;
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;

  span {
    color: #c09c5c;
  }
`;

const SubHeader = styled.h4`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 340px) {
    font-size: 1rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;

  /* Estiliza o input number para remover as setas */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield; /* Para Firefox */
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background-color: #8c7444;
  }
`;

const ValueButton = styled.button`
  background-color: #333333;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
  margin-inline: auto;

  &:hover {
    background-color: #444444;
  }
`;

export default function DepositModal({ close, attBalance }) {
  const [stage, setStage] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [depositValue, setDepositValue] = useState(null);
  const [qrCodeValue] = useState(() => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 64; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  });

  const handleDeposit = async () => {
    try {
      const token = localStorage.getItem("userToken");
      if (token) {
        console.log(token);
        const response = await fetch(
          "https://userdeposit-qj6yfdqbiq-uc.a.run.app",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`, // substitua `yourJwtToken` pelo seu token JWT
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount: depositValue,
              type: "deposit",
            }),
          }
        );

        if (!response.ok) {
          toast.error(`Error making deposit`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        const randomTime = Math.floor(Math.random() * 5000);
        console.log(randomTime);

        setTimeout(() => {
          setStage("loading");
          setTimeout(() => {
            setStage(2);
            attBalance();
          }, 1000);
        }, randomTime);

        return data;
      }
      toast.error("You need to be logged in to make a deposit");
      throw new Error("Invalid JWT");
    } catch (error) {
      console.error("Error making deposit:", error);
    }
  };

  const handleNext = () => {
    setStage("loading");
    setTimeout(() => {
      setStage(stage + 1);
    }, 1000);
    handleDeposit();
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const inputElement = inputRef.current;

    const handleBeforeInput = (e) => {
      const beforeValue = inputElement.value;

      const handleInput = () => {
        const currentValue = parseInt(inputElement.value, 10);

        if (inputElement.validity.patternMismatch || currentValue > 10000) {
          inputElement.value = beforeValue;
          setDepositValue(parseInt(beforeValue) || null);
        } else {
          setDepositValue(currentValue || null);
        }
      };

      inputElement.addEventListener("input", handleInput, { once: true });
    };

    inputElement.addEventListener("beforeinput", handleBeforeInput);

    return () => {
      inputElement.removeEventListener("beforeinput", handleBeforeInput);
    };
  }, []);

  return (
    <>
      <Overlay onClick={() => close(true)} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => close(true)}
        >
          X
        </div>
        {stage === "loading" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <LoaderComponent />
          </div>
        )}
        {stage === 0 && (
          <>
            <Header>DEPOSIT</Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>

            <SubHeader>Add discount coupon</SubHeader>
            <Input
              placeholder="Discount Coupon"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />

            <SubHeader>Deposit value</SubHeader>
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <ValueButton onClick={() => setDepositValue(depositValue + 250)}>
                250
              </ValueButton>
              <ValueButton onClick={() => setDepositValue(depositValue + 1000)}>
                1000
              </ValueButton>
              <ValueButton onClick={() => setDepositValue(depositValue + 5000)}>
                5000
              </ValueButton>
            </div>

            <Input
              ref={inputRef}
              placeholder="Digite o valor"
              value={depositValue}
              type="text"
              inputMode="numeric"
              max="10000"
              pattern="[0-9]+"
            />

            <Button
              onClick={
                depositValue <= 0 || depositValue === null
                  ? undefined
                  : handleNext
              }
            >
              NEXT
            </Button>
          </>
        )}

        {stage === 1 && (
          <>
            <Header>LAST STEP</Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>
            <SubHeader>Read the QR code</SubHeader>
            <div
              style={{
                padding: "10px",
                background: "white",
                width: "68%",
                margin: "auto",
              }}
            >
              <QRCodeSVG value={qrCodeValue} size={256} />
            </div>
            <Button onClick={() => navigator.clipboard.writeText(qrCodeValue)}>
              COPY QR CODE
            </Button>
          </>
        )}
        {stage === 2 && (
          <>
            <Header style={{ marginBlock: "auto" }}>
              TRANSACTION ADDED <span>SUCCESSFULLY</span>
            </Header>
          </>
        )}
      </Popup>
    </>
  );
}
