import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

export default function Layout3({ darkMode }) {
  return (
    <div className={`${darkMode ? "cs_dark" : ""}`}>
      <Header
        logoUrl={darkMode ? "/images/logo_white.svg" : "/images/logo.svg"}
        cart
        actionBtnText="Cadastre-se"
        actionBtnUrl="/register"
      />
      <Outlet />
      <Footer />
    </div>
  );
}
