import React, { useState } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { Icon } from "@iconify/react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, app } from "../../context/firebase";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../Loader/Loader";
import toast from "react-hot-toast";

export default function Cadastro() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCadastro = async (e) => {
    e.preventDefault();
    if (!email || !password || !confirmPassword) {
      toast.error("Preencha todos os campos");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Email inválido");
    } else if (!(confirmPassword === password)) {
      toast.error("Senhas não coincidem");
    } else {
      setLoading(true);
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          user.getIdToken().then((token) => {
            // Exibe o token JWT no console
            console.log("JWT Token:", token);

            // Armazena o token no localStorage com tempo de expiração
            const expirationTime = new Date().getTime() + 3 * 60 * 60 * 1000;
            localStorage.setItem("userToken", token);
            localStorage.setItem("tokenExpiration", expirationTime);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            navigate("/profile");
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === "auth/email-already-in-use") {
            toast.error("Email already in use");
          } else {
            toast.error("Unknown error, please try again later");
          }
          setLoading(false);
        });
    }
  };
  pageTitle("Sign Up");
  return (
    <>
      {loading && <LoaderComponent fullPage={true} />}
      <Spacing lg="90" md="90" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <div style={{ marginBlock: "auto", position: "sticky" }}>
                <SectionHeadingStyle3
                  title="Sign Up"
                  subTitle="Create Account"
                  shape="shape_6"
                />
                <Spacing lg="90" md="0" />
              </div>
            </div>
            <div style={{ marginBottom: "-6.2rem" }} className="col-lg-6">
              <div className="cs_contact_form_wrap">
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form className="cs_contact_form">
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Email
                  </label>
                  <input
                    placeholder="Your email"
                    type="text"
                    className="cs_form_field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Password
                  </label>
                  <input
                    placeholder="Your password"
                    type="password"
                    className="cs_form_field"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Confirm Password
                  </label>
                  <input
                    placeholder="Confirm password"
                    type="password"
                    className="cs_form_field"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <button
                    onClick={(e) => handleCadastro(e)}
                    className="cs_btn cs_style_1"
                    disabled={loading}
                  >
                    Sign Up{" "}
                    <span>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                    </span>
                  </button>

                  <div className="cs_height_38 cs_height_lg_25" />
                  <div className="cs_height_38 cs_height_lg_25" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
    </>
  );
}
