import React, { useState, useEffect, useCallback } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { Icon } from "@iconify/react";
import LoaderComponent from "../Loader/Loader";
import toast from "react-hot-toast";
import styled from "styled-components";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../context/firebase";
import { getCroppedImg } from "./cropUtils";
import Cropper from "react-easy-crop";

export default function Profile() {
  const [profileData, setProfileData] = useState({
    uid: "",
    accessLevel: 0,
    avatar: "none",
    name: "",
    email: "",
    cpf: "",
    birthdate: "",
    gender: "",
    nationality: "",
    address: "",
    phone: "",
    income: 0,
    balance: 0,
    verified: false,
    investor: false,
    cvmApproved: false,
  });
  const [originalData, setOriginalData] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newAvatar, setNewAvatar] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropComplete, setCropComplete] = useState(false);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = URL.createObjectURL(file);
      setNewAvatar(imageDataUrl);
    }
  };

  const handleCropAndUpload = async () => {
    if (!newAvatar || !croppedAreaPixels) return;
    setCropComplete(true);

    try {
      const croppedAndResizedBlob = await getCroppedImg(
        newAvatar,
        croppedAreaPixels,
        200,
        200
      );
      const storageRef = ref(storage, `avatars/${profileData.uid}`);
      await uploadBytes(storageRef, croppedAndResizedBlob);
      const downloadURL = await getDownloadURL(storageRef);
      setProfileData((prevData) => ({ ...prevData, avatar: downloadURL }));
      toast.success("Avatar updated successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading avatar:", error);
      toast.error("Error uploading avatar");
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error("Invalid session, please login again.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          "https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401) {
            toast.error("Session expired, please login again.");
            return;
          }
          throw new Error("Error fetching profile data");
        }

        const data = await response.json();
        const filteredData = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value === "none" || value === "default" ? "" : value,
          ])
        );
        setProfileData(filteredData);
        setOriginalData(data);
      } catch (error) {
        console.error("Error fetching profile data", error);
        toast.error("Error loading profile data");
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const isValidCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let firstVerifier = (sum * 10) % 11;
    if (firstVerifier === 10) firstVerifier = 0;
    if (firstVerifier !== parseInt(cpf.charAt(9))) return false;

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }

    let secondVerifier = (sum * 10) % 11;
    if (secondVerifier === 10) secondVerifier = 0;
    return secondVerifier === parseInt(cpf.charAt(10));
  };

  const formatCPF = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      .substring(0, 14);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: name === "cpf" ? formatCPF(value) : value,
    }));
  };

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Session expired, please login again.");
      setLoading(false);
      return;
    }

    const updatedData = {
      ...profileData,
      avatar: newAvatar || profileData.avatar,
    };

    try {
      const response = await fetch(
        "https://updateuserinfo-qj6yfdqbiq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) throw new Error("Error saving profile data");

      window.location.reload();
      setIsEditable(false);
      setOriginalData(updatedData);
    } catch (error) {
      console.error("Error updating profile", error);
      toast.error("Error updating profile");
    } finally {
      setLoading(false);
    }
  };

  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  pageTitle("Profile");

  return (
    <>
      <Spacing lg="90" md="90" />
      <section>
        <div className="container">
          <SectionHeadingStyle3
            title="My Profile"
            subTitle="Profile"
            shape="shape_6"
          />
          {loading ? (
            <LoaderComponent opacity={1} fullPage={true} />
          ) : (
            <FormContainer>
              <form onSubmit={handleSaveProfile}>
                {newAvatar && !cropComplete ? (
                  <CropContainer
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      width: "100%",
                      maxWidth: "200px",
                    }}
                  >
                    <Cropper
                      image={newAvatar}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      style={{
                        containerStyle: { width: "300px", height: "300px" },
                      }}
                    />
                    <ButtonImage onClick={handleCropAndUpload}>
                      Confirm
                    </ButtonImage>
                  </CropContainer>
                ) : (
                  <AvatarContainer>
                    <AvatarImage
                      src={
                        profileData.avatar !== "none"
                          ? profileData.avatar
                          : "https://static.vecteezy.com/system/resources/previews/005/129/844/non_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg"
                      }
                      alt="Avatar"
                    />
                    <Button as="label" htmlFor="avatarUpload">
                      <Icon icon="bi:camera" style={{ marginRight: "8px" }} />
                      Change Avatar
                    </Button>
                    <input
                      id="avatarUpload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleAvatarChange}
                    />
                  </AvatarContainer>
                )}

                <label htmlFor="name">Name</label>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={profileData.name}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="cpf">CPF</label>
                <Input
                  id="cpf"
                  type="text"
                  name="cpf"
                  placeholder="CPF"
                  value={profileData.cpf}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="birthdate">Date of Birth</label>
                <Input
                  id="birthdate"
                  type="date"
                  name="birthdate"
                  placeholder="Date of Birth"
                  value={profileData.birthdate}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="gender">Gender</label>
                <Input
                  id="gender"
                  type="text"
                  name="gender"
                  placeholder="Gender"
                  value={profileData.gender}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="nationality">Nationality</label>
                <Input
                  id="nationality"
                  type="text"
                  name="nationality"
                  placeholder="Nationality"
                  value={profileData.nationality}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="address">Address</label>
                <Input
                  id="address"
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={profileData.address}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="phone">Phone</label>
                <Input
                  id="phone"
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={profileData.phone}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="income">Annual Income</label>
                <Input
                  id="income"
                  type="text"
                  name="income"
                  placeholder="Annual Income"
                  value={profileData.income}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (isEditable) {
                      handleSaveProfile(e);
                    } else {
                      toggleEdit();
                    }
                  }}
                  style={{ marginTop: "15px" }}
                >
                  {isEditable ? "Save Changes" : "Edit"}
                </Button>
              </form>
            </FormContainer>
          )}
        </div>
      </section>
    </>
  );
}

const FormContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 40px;
  background-color: #171717;
  border-radius: 8px;
  border: 3px solid #c8af86;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 150px;
  gap: 20px;
  color: #fff;
`;

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 400px;
  margin: auto;
  margin-left: 105px;

  @media (max-width: 770px) {
    margin-left: 70px;
  }

  @media (max-width: 575px) {
    margin-left: 80px;
  }

  @media (max-width: 550px) {
    margin-left: 70px;
  }

  @media (max-width: 520px) {
    margin-left: 60px;
  }

  @media (max-width: 500px) {
    margin-left: 50px;
  }

  @media (max-width: 475px) {
    margin-left: 40px;
  }

  @media (max-width: 450px) {
    margin-left: 30px;
  }

  @media (max-width: 425px) {
    margin-left: 10px;
  }

  @media (max-width: 400px) {
    margin-left: -5px;
  }

  @media (max-width: 375px) {
    margin-left: -15px;
  }
`;

const ButtonImage = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #866d40;
  color: white;
  border: none;
  border-radius: 4px;
  zindex: 999;
  margin-top: 300px;
  margin-left: 100px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: #c8af86;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
`;

const AvatarImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  background-color: #ddd;

  border: 2px solid #ddd;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #0a0a0a;
  border: 1px solid #c8af86;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #866d40;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: #c8af86;
  }
`;
