import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { FaTimes, FaPlus, FaMinus } from "react-icons/fa";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function InvestModal({
  close,
  price,
  projectId,
  projectName,
  balance,
}) {
  const [shares, setShares] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const sharePrice = price;
  const navigate = useNavigate();

  const handleBuy = async () => {
    const totalValue = shares * sharePrice;

    if (totalValue > balance) {
      toast.error("Insufficient balance for this purchase");
      return;
    }

    try {
      setLoading(true);
      const app = getApp();
      const auth = getAuth(app);
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      const jwtToken = await user.getIdToken();

      const response = await fetch(
        "https://userbuyshares-qj6yfdqbiq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            projectId: projectId,
            numberOfShares: shares,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to buy shares");
      }

      const data = await response.json();
      setSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    close();
  };

  const handleView = () => {
    navigate("/my-investments");
  };

  return (
    <>
      <Overlay onClick={() => close(true)} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => close(true)}
        >
          <FaTimes size={24} color="#fff" />
        </div>
        {loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : success ? (
          <SuccessMessage style={{ marginTop: "45px" }}>
            <h2>Congratulations!!!</h2>
            <h3>You just became an shareholder in:</h3>
            <h4>{projectName}</h4>
            <div
              style={{ display: "flex", flexDirection: "column", width: "90%" }}
            >
              <Button onClick={handleView} style={{ marginBottom: "10px" }}>
                View
              </Button>
              <Button onClick={handleOk} style={{ backgroundColor: "gray" }}>
                OK
              </Button>
            </div>
          </SuccessMessage>
        ) : (
          <>
            <Header>BUY SHARES</Header>
            <SubHeader>Share Price: ${sharePrice}</SubHeader>
            <SubHeader>Number of Shares</SubHeader>
            <InputContainer>
              <IconButton onClick={() => setShares(Math.max(0, shares - 1))}>
                <FaMinus />
              </IconButton>
              <Input
                type="number"
                value={shares}
                onChange={(e) => setShares(Number(e.target.value))}
                min="0"
              />
              <IconButton onClick={() => setShares(shares + 1)}>
                <FaPlus />
              </IconButton>
            </InputContainer>
            <SubHeader>Total: ${(shares * sharePrice).toFixed(2)}</SubHeader>
            <TermsText>
              <TermsLink>Terms and Conditions </TermsLink>apply to this
              purchase.
            </TermsText>
            <Button onClick={handleBuy} disabled={shares <= 0 || loading}>
              BUY
            </Button>
          </>
        )}
      </Popup>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 520px;
  width: 450px;
  background-color: #121212;
  z-index: 901;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: white;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;

  span {
    color: #c09c5c;
  }
`;

const SubHeader = styled.h4`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 340px) {
    font-size: 1rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconButton = styled.button`
  background-color: #1c1c1c;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;

  &[type="number"] {
    -moz-appearance: textfield; /* Para Firefox */
    appearance: textfield; /* Remove setas padrão */
  }

  /* Remove setas no Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove setas no Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background-color: #8c7444;
  }
`;

const TermsText = styled.p`
  font-size: 0.9rem;
  color: #fff;
  margin-top: 10px;
  text-align: center;
  margin-bottom: -20px;
`;

const TermsLink = styled.span`
  color: #2c65ff;
  cursor: pointer;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Loader = styled.div`
  border: 4px solid #c09c5c;
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s linear infinite;
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  color: #ffffff;

  h2 {
    font-size: 2rem;
    color: #c09c5c;
  }

  p {
    font-size: 1.2rem;
  }
`;
