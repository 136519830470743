import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DropDown from "./DropDown";
import { signOut } from "firebase/auth";
import { auth } from "../../context/firebase";
import axios from "axios";
import DepositModal from "../Modals/DepositModal";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";

export default function Header({
  logoUrl,
  colorVariant,
  cart,
  actionBtnText,
  actionBtnUrl,
}) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [logged, setLogged] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    "https://static.vecteezy.com/system/resources/previews/005/129/844/non_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg"
  ); // Estado para a foto de perfil
  const [balance, setBalance] = useState("0.00");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [updateData, setUpdateData] = useState(0);
  const [isSmallScreen, setIsSmallscreen] = useState(window.innerWidth < 700);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallscreen(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const existingToken = localStorage.getItem("userToken");
    if (existingToken) {
      console.log(existingToken);
      setLogged(true);

      axios
        .get("https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${existingToken}`,
          },
        })
        .then((response) => {
          if (response.data && response.data.balance) {
            setBalance(parseFloat(response.data.balance));
            response.data.avatar !== "none" &&
              setProfilePicture(response.data.avatar);
          }
          if (response.data && response.data.accessLevel === 3) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        })
        .catch((error) => {
          console.error("Erro ao obter saldo do usuário:", error);
        });
    }
  }, [updateData]);

  const attBalance = () => {
    setUpdateData(updateData + 1);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        localStorage.removeItem("userToken");
        localStorage.removeItem("tokenExpiration");
        setLogged(false);
        navigate("/");
      })
      .catch((error) => console.error("Error signing out:", error));
  };

  const handleDepositModal = () => {
    setIsDepositModal(!isDepositModal);
  };

  return (
    <>
      {isDepositModal && (
        <DepositModal close={handleDepositModal} attBalance={attBalance} />
      )}
      <header
        className={`cs_site_header cs_style_1 cs_sticky_header ${
          colorVariant ? colorVariant : "cs_primary_color"
        } ${isSticky ? "cs_gescout_show" : ""}`}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="cs_main_header_left">
                <Link className="cs_site_branding" to="/">
                  <ImageLogo src={"/barzel-logo.png"} alt="Logo" />
                </Link>
              </div>
              <div className="cs_main_header">
                <div className="cs_nav cs_medium cs_primary_font">
                  <ul
                    className={`${
                      mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                    }`}
                    modal
                  >
                    <li className="">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="">
                      <Link to="/marketplace">Marketplace</Link>
                    </li>
                    {isSmallScreen && isAuthorized && (
                      <li>
                        <Link to="/create-project">New Project</Link>
                      </li>
                    )}
                    {isSmallScreen && (
                      <li>
                        <Link onClick={handleDepositModal}>Deposit</Link>
                      </li>
                    )}
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs_menu_toggle cs_teggle_active"
                        : "cs_menu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </div>
              </div>
              <div className="cs_main_header_right">
                {cart && (
                  <a href="shop-cart" className="cs_header_cart">
                    <svg
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_307_180)">
                        <path
                          d="M19.25 5.5H16.5C16.5 4.04131 15.9205 2.64236 14.8891 1.61091C13.8576 0.579463 12.4587 0 11 0C9.54131 0 8.14236 0.579463 7.11091 1.61091C6.07946 2.64236 5.5 4.04131 5.5 5.5H2.75C2.02065 5.5 1.32118 5.78973 0.805456 6.30546C0.289731 6.82118 0 7.52065 0 8.25L0 17.4167C0.00145554 18.6318 0.484808 19.7967 1.34403 20.656C2.20326 21.5152 3.3682 21.9985 4.58333 22H17.4167C18.6318 21.9985 19.7967 21.5152 20.656 20.656C21.5152 19.7967 21.9985 18.6318 22 17.4167V8.25C22 7.52065 21.7103 6.82118 21.1945 6.30546C20.6788 5.78973 19.9793 5.5 19.25 5.5ZM11 1.83333C11.9725 1.83333 12.9051 2.21964 13.5927 2.90728C14.2804 3.59491 14.6667 4.52754 14.6667 5.5H7.33333C7.33333 4.52754 7.71964 3.59491 8.40728 2.90728C9.09491 2.21964 10.0275 1.83333 11 1.83333ZM20.1667 17.4167C20.1667 18.146 19.8769 18.8455 19.3612 19.3612C18.8455 19.8769 18.146 20.1667 17.4167 20.1667H4.58333C3.85399 20.1667 3.15451 19.8769 2.63879 19.3612C2.12306 18.8455 1.83333 18.146 1.83333 17.4167V8.25C1.83333 8.00688 1.92991 7.77373 2.10182 7.60182C2.27373 7.42991 2.50688 7.33333 2.75 7.33333H5.5V9.16667C5.5 9.40978 5.59658 9.64294 5.76849 9.81485C5.94039 9.98676 6.17355 10.0833 6.41667 10.0833C6.65978 10.0833 6.89294 9.98676 7.06485 9.81485C7.23676 9.64294 7.33333 9.40978 7.33333 9.16667V7.33333H14.6667V9.16667C14.6667 9.40978 14.7632 9.64294 14.9352 9.81485C15.1071 9.98676 15.3402 10.0833 15.5833 10.0833C15.8264 10.0833 16.0596 9.98676 16.2315 9.81485C16.4034 9.64294 16.5 9.40978 16.5 9.16667V7.33333H19.25C19.4931 7.33333 19.7263 7.42991 19.8982 7.60182C20.0701 7.77373 20.1667 8.00688 20.1667 8.25V17.4167Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_307_180">
                          <rect width={22} height={22} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="cs_header_cart_label">0</span>
                  </a>
                )}
                {logged ? (
                  <ProfileSection>
                    {/* Card do saldo do usuário */}

                    <UserBalanceCard>
                      <span>$ {balance.toLocaleString()}</span>
                    </UserBalanceCard>
                    <UserBalanceCard2 onClick={handleDepositModal}>
                      Deposit
                    </UserBalanceCard2>
                    {isAuthorized && (
                      <UserBalanceCard2
                        onClick={() => navigate("/create-project")}
                      >
                        New Project
                      </UserBalanceCard2>
                    )}
                    {/* Foto de perfil com dropdown */}
                    <ProfilePicture
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                      <img
                        src={profilePicture}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      />
                      <div />
                      {/* Dropdown fixo abaixo da foto */}
                      {dropdownOpen && (
                        <DropdownWrapper>
                          <DropdownMenu>
                            <Button onClick={() => navigate("/profile")}>
                              Profile
                            </Button>

                            {/* <Button onClick={() => navigate("/create-order")}>
                              Create Order
                            </Button> */}

                            <Button
                              onClick={() =>
                                navigate("/transactions?page=1&limit=10")
                              }
                            >
                              Transactions
                            </Button>

                            <Button
                              onClick={() =>
                                navigate("/my-portfolio?page=1&limit=10")
                              }
                            >
                              Portfolio
                            </Button>

                            {/* <Button>Wallet</Button> */}
                            <Button onClick={handleLogout}>Logout</Button>
                          </DropdownMenu>
                        </DropdownWrapper>
                      )}
                    </ProfilePicture>
                  </ProfileSection>
                ) : (
                  <ButtonsSign>
                    <UserBalanceCard3
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </UserBalanceCard3>
                    <UserBalanceCard3
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/register")}
                    >
                      Register
                    </UserBalanceCard3>
                  </ButtonsSign>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ButtonsSign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const UserBalanceCard = styled.div`
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #c09c5c;
  font-weight: bold;

  @media (max-width: 420px) {
    padding: 8px 12px;
  }

  @media (max-width: 400px) {
    display: none;
  }
`;

const UserBalanceCard2 = styled.div`
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #c09c5c;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #a07c4b;
    transform: scale(1.03);
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const UserBalanceCard3 = styled.div`
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #c09c5c;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    background-color: #a07c4b;
    transform: scale(1.03);
  }

  @media (max-width: 390px) {
    font-size: 12px;
    width: 60px;
    height: 30px;
  }
`;

const ProfilePicture = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  background: url(${(props) => props.profilePicture});
  background-size: cover !important;
  background-position: center !important;
  position: relative;
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -15px;
`;

const fadeInSlideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 25px; /* Adjusted to appear below the profile picture */
  left: -50px;
  padding: 10px;
  background-color: rgba(
    30,
    30,
    30,
    0.95
  ); /* Slightly transparent dark background */
  border: 1px solid #444; /* Optional border for elegance */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow for elegance */
  z-index: 10;
  width: 160px; /* Adjust width to be more compact */
  animation: ${fadeInSlideDown} 0.3s ease forwards;
`;

const Button = styled.button`
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #c89c5c, #a07c4b); /* Subtle gradient */
  color: #fff;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: linear-gradient(
      135deg,
      #a07c4b,
      #8a6b3f
    ); /* Darker gradient on hover */
    transform: scale(1.05); /* Slightly larger on hover */
  }
`;

const ImageLogo = styled.img`
  width: 150px;

  @media (max-width: 430px) {
    width: 100px;
  }
`;
