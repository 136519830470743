import React, { useEffect, useState } from "react";
import Spacing from "../../Spacing";
import Showdown from "showdown";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InvestModal from "../../Modals/InvestModal";
import styled, { keyframes } from "styled-components";
import { FaTimes } from "react-icons/fa";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getUserBalance } from "../investments/GetUserBalance";


export default function InvestInfo({ ProjectData }) {
  const [markdownContent, setMarkdownContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isInvestModal, setisInvestModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [balance, setBalance] = useState(0); // Adicione o estado para balance
  const navigate = useNavigate();

  const handleInvestModal = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setisInvestModal(!isInvestModal);
    } else {
      setIsLoginModal(true);
    }
  };

  const handleLoginModalClose = () => {
    setIsLoginModal(false);
  };

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  useEffect(() => {
    const fetchMarkdown = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-barzelbank.cloudfunctions.net/fetchFullProjectDescription?projectId=${ProjectData.projectId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();

        const converter = new Showdown.Converter({
          tables: true,
          simplifiedAutoLink: true,
          strikethrough: true,
          tasklists: true,
          emoji: true,
        });

        const markdown = converter.makeHtml(text);
        setMarkdownContent(markdown);
      } catch (error) {
        console.error("Error fetching markdown content:", error);
        setMarkdownContent("Failed to load description.");
      } finally {
        setIsLoading(false);
      }
    };

    if (ProjectData.projectId) {
      fetchMarkdown();
    }
  }, [ProjectData.projectId]);

  useEffect(() => {
    const fetchBalance = async () => {
      const userBalance = await getUserBalance();
      if (userBalance !== null) {
        setBalance(userBalance);
      }
    };

    fetchBalance();
  }, []);

  const participationPercentage = (
    (((ProjectData.soldShares + ProjectData.reservedShares) *
      ProjectData.sharePrice) /
      (ProjectData.totalShares * ProjectData.sharePrice)) *
    100
  ).toFixed(2);

  return (
    <>
      {isInvestModal && (
        <InvestModal
          close={handleInvestModal}
          price={ProjectData.sharePrice}
          projectId={ProjectData.projectId}
          projectName={ProjectData.title}
          balance={balance}
        />
      )}
      {isLoginModal && (
        <LoginModal
          close={handleLoginModalClose}
          redirect={handleLoginRedirect}
        />
      )}
      <div className="cs_about cs_style_1">
        <div className="container">
          <div className="row align-items-top cs_gap_y_40">
            <div className="col-lg-6">
              <div
                style={{
                  height: "650px",
                  borderRadius: "10px",
                  backgroundImage: `url(${ProjectData.thumbnailUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              {isLoading ? (
                <>
                  <div style={{ marginBlock: "1rem" }}>
                    <Skeleton
                      height={50}
                      baseColor="#333332"
                      highlightColor="#222221"
                    />
                    <Skeleton
                      height={50}
                      style={{ width: "75%" }}
                      baseColor="#333332"
                      highlightColor="#222221"
                    />
                  </div>
                  <div style={{ marginBlock: "1rem" }}>
                    <Skeleton
                      count={6}
                      baseColor="#333332"
                      highlightColor="#222221"
                    />
                    <Skeleton
                      style={{ width: "75%" }}
                      count={2}
                      baseColor="#333332"
                      highlightColor="#222221"
                    />
                  </div>
                  <div style={{ marginBlock: "1rem" }}>
                    <Skeleton
                      count={3}
                      baseColor="#333332"
                      highlightColor="#222221"
                    />
                    <Skeleton
                      style={{ width: "40%" }}
                      count={1}
                      baseColor="#333332"
                      highlightColor="#222221"
                    />
                  </div>
                </>
              ) : (
                <div
                  className="markdown-content"
                  dangerouslySetInnerHTML={{ __html: markdownContent }}
                  style={{
                    color: "#fff",
                    padding: "20px",
                    lineHeight: "1.6",
                  }}
                />
              )}
            </div>
            <div style={{ padding: "0 1rem" }} className="col-lg-6">
              <div className="cs_section_heading cs_style_1">
                <p className="cs_section_subtitle cs_accent_color cs_fs_18 mb-0">
                  Project
                </p>
                <Spacing lg="10" md="5" />
                <h2 className="cs_section_title cs_fs_50 mb-0">
                  {ProjectData.title}
                </h2>
                <Spacing lg="24" md="24" />

                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    marginBottom: "2rem",
                    borderRadius: "10px",
                    background: "#FFF",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: `${participationPercentage}%`,
                      height: "100%",
                      background: "#c89c5c",
                      borderRadius: "10px",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      transition: "width 0.5s ease",
                    }}
                  ></div>
                  <span
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: participationPercentage > 10 ? "#FFF" : "#222221",
                      fontWeight: "bold",
                    }}
                  >
                    {participationPercentage}%
                  </span>
                </div>

                <h2 className="cs_section_title cs_fs_30 mb-0">
                  $
                  {(
                    ProjectData.soldShares * ProjectData.sharePrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>

                <Spacing lg="10" md="5" />
                <h4
                  style={{ fontWeight: "normal" }}
                  className="cs_section_title cs_fs_30 mb-0"
                >
                  {ProjectData.soldShares} Shares already sold
                </h4>
                <Spacing lg="10" md="5" />
                <Spacing lg="10" md="5" />
                <button
                  style={{
                    borderRadius: "5px",
                    height: "50px",
                    padding: "25px 60px",
                    fontSize: "1.15rem",
                    fontWeight: "normal",
                  }}
                  onClick={handleInvestModal}
                  className={`cs_btn cs_style_1  ${"cs_btn_accent"}`}
                >
                  Invest
                </button>
                <Spacing lg="10" md="5" />
                <Spacing lg="10" md="5" />
                <div style={{ color: "white", fontSize: "1.1rem" }}>
                  <p>Minimum Invest: ${ProjectData.sharePrice}</p>
                  <p>Share value: ${ProjectData.sharePrice}</p>
                  <p>Participation: {participationPercentage}%</p>
                </div>
                <Spacing lg="10" md="5" />
                <h3 style={{ color: "#BF9A5D", fontSize: "2rem", margin: "0" }}>
                  Description
                </h3>
                <p style={{ color: "#fff" }}>{ProjectData.description}</p>
                <Spacing lg="24" md="24" />
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function LoginModal({ close, redirect }) {
  return (
    <>
      <Overlay onClick={close} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={close}
        >
          <FaTimes size={15} color="#fff" />
        </div>
        <Header>Please login to <span style={{color:''}}>invest</span></Header>
        <Button onClick={redirect}>OK</Button>
      </Popup>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 350px;
  width: 400px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #121212;
  z-index: 901;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: white;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  width: 90%;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  // margin-top: auto;
  width: 90%;
  align-self: center;

  &:hover {
    background-color: #8c7444;
  }
`;