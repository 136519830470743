// FILE: getUserBalance.js
import axios from "axios";

export const getUserBalance = async () => {
  try {
    const existingToken = localStorage.getItem("userToken");
    if (!existingToken) {
      console.log("Token não encontrado");
      return null;
    }

    console.log("Token encontrado:", existingToken);

    const response = await axios.get("https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${existingToken}`,
      },
    });

    console.log("Resposta da API:", response);

    if (response.data && response.data.balance) {
      console.log("Saldo do usuário:", response.data.balance);
      return parseFloat(response.data.balance).toFixed(2);
    } else {
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter saldo do usuário:", error);
    return null;
  }
};