import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

export default function Offers() {
  const navigate = useNavigate();
  const location = useLocation();

  // Get page from URL or default to 1
  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [uid, setUid] = useState(null);

  // Update URL when page changes
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`/offers?page=${newPage}&limit=10`);
  };

  useEffect(() => {
    const fetchUid = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(
          "https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUid(response.data.uid);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUid();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(
          `https://getorderbook-qj6yfdqbiq-uc.a.run.app?page=${currentPage}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          const ordersWithOwnerId = response.data.orders.map((order) => ({
            ...order,
            ownerId: order.userId,
          }));
          setOrders(ordersWithOwnerId);
          setTotalPages(response.data.pagination.totalPages);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [currentPage]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <h2>Shares Offerbook</h2>
          <div className="table-responsive">
            <table className="cs_cart_table">
              <thead>
                <tr>
                  <th>Project</th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Shares
                  </th>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Price per Share
                  </th>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Total
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan="6"
                      style={{ textAlign: "center", padding: "50px 0" }}
                    >
                      Loading...
                    </td>
                  </tr>
                ) : (
                  orders.map((order) => (
                    <tr key={order.id}>
                      <td>
                        <div className="cs_cart_table_media">
                          <img src={order.imageUrl} alt="Thumb" />
                          <h3>{order.projectName}</h3>
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {order.numberOfShares}
                      </td>
                      <td></td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        ${order.pricePerShare}
                      </td>
                      <td></td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        ${order.totalValue}
                      </td>
                      <td className="text-center">
                        <button className="cs_cart-table-close">
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "20px",
                          }}
                        >
                          <button
                            style={{
                              background:
                                uid === order.ownerId ? "#e83238" : "#11C160",
                              padding: "15px 30px",
                              border: "none",
                              width: "100%",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {uid === order.ownerId ? "CANCEL" : "BUY"}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div
            className="cs_pagination"
            style={{
              textAlign: "center",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <button
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
              className="cs_btn cs_style_1"
              style={{ width: "100px" }}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                handlePageChange(Math.min(currentPage + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="cs_btn cs_style_1"
              style={{ width: "100px" }}
            >
              Next
            </button>
          </div>

          <div className="cs_height_30 cs_height_lg_30" />
        </div>
      </div>
    </div>
  );
}
