export const getCroppedImg = (imageSrc, crop, width, height) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        canvas.width = width;
        canvas.height = height;
  
        ctx.drawImage(
          image,
          crop.x, // Posição X do recorte
          crop.y, // Posição Y do recorte
          crop.width, // Largura do recorte
          crop.height, // Altura do recorte
          0, // Posição X no canvas
          0, // Posição Y no canvas
          width, // Largura de redimensionamento
          height // Altura de redimensionamento
        );
  
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Erro ao criar blob"));
            return;
          }
          resolve(blob);
        }, "image/jpeg");
      };
      image.onerror = (error) => reject(error);
    });
  };
  