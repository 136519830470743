import React, { useState } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { Icon } from "@iconify/react";
import { sendPasswordResetEmail } from "firebase/auth";
import LoaderComponent from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { auth } from "../../context/firebase";
import toast from "react-hot-toast";

export default function ChangePassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = (e) => {
    e.preventDefault();
    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Notifique o usuário que o e-mail foi enviado
        setEmail("");
        toast.success("E-mail de redefinição de senha enviado!");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((error) => {
        // Trate erros (exemplo: e-mail inválido)
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/user-not-found") {
          toast.error("Usuário não encontrado");
        } else {
          toast.error("Erro ao enviar e-mail de redefinição de senha");
        }

        console.error("Erro ao enviar e-mail:", errorMessage);
      });
    setLoading(false);
  };

  pageTitle("Esqueci a senha");
  return (
    <>
      <Spacing lg="90" md="90" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <div style={{ marginBlock: "auto" }}>
                <SectionHeadingStyle3
                  title="Resetar senha"
                  subTitle="Esqueci a senha"
                  shape="shape_6"
                />
                <Spacing lg="90" md="0" />
              </div>
            </div>
            <div style={{ marginBottom: "-6.2rem" }} className="col-lg-6">
              <div
                style={{
                  height: "800px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="cs_contact_form_wrap"
              >
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form className="cs_contact_form">
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Email
                  </label>
                  <input
                    placeholder="Seu email"
                    type="text"
                    className="cs_form_field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      onClick={(e) => handlePasswordReset(e)}
                      className="cs_btn cs_style_1"
                    >
                      {loading ? (
                        <>
                          Carregando
                          <LoaderComponent size={20} borderThickness={4} />
                        </>
                      ) : (
                        <>
                          Enviar Email{" "}
                          <span>
                            <i>
                              <Icon icon="fa6-solid:arrow-right" />
                            </i>
                            <i>
                              <Icon icon="fa6-solid:arrow-right" />
                            </i>
                          </span>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
    </>
  );
}
