import { useRef, useEffect } from "react";

export function useNumericInput(maxValue = 10000, onChange) {
  const inputRef = useRef(null);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (!inputElement) return;

    const handleBeforeInput = (e) => {
      const beforeValue = inputElement.value;

      const handleInput = () => {
        // Remove caracteres não numéricos, mantendo ponto e vírgula
        const sanitizedValue = inputElement.value
          .replace(/[^\d.,]/g, "")
          // Substitui vírgula por ponto
          .replace(",", ".")
          // Remove pontos/vírgulas extras
          .replace(/[.,](?=.*[.,])/g, "")
          // Remove ponto/vírgula se for primeiro caractere
          .replace(/^[.,]/, "");

        const currentValue = parseFloat(sanitizedValue);

        if (currentValue > maxValue || isNaN(currentValue)) {
          inputElement.value = beforeValue;
          onChange?.(parseFloat(beforeValue) || null);
        } else {
          inputElement.value = sanitizedValue;
          onChange?.(currentValue || null);
        }
      };

      inputElement.addEventListener("input", handleInput, { once: true });
    };

    // ... existing code ...
  }, [maxValue, onChange]);

  return inputRef;
}
