import React from "react";
import styled, { keyframes } from "styled-components";

// Animação de rotação
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${(props) => props.opacity});
  z-index: 9999;
`;

const Loader = styled.div`
  border: ${(props) => props.borderThickness}px solid #f0f0f0; /* Cor de fundo do círculo */
  border-top: ${(props) =>
    `${props.borderThickness}px solid ${props.color}`}; /* Cor do segmento do círculo */
  border-radius: 50%;
  width: ${(props) => props.size}px; /* Tamanho do loader */
  height: ${(props) => props.size}px; /* Tamanho do loader */
  animation: ${spin} 0.6s linear infinite; /* Animação de rotação */
`;

const LoaderComponent = ({
  size = 60,
  borderThickness = 8,
  color = "#c09c5c",
  fullPage = false,
  opacity = 0.6,
}) => {
  const loaderElement = (
    <Loader color={color} size={size} borderThickness={borderThickness} />
  );

  return fullPage ? (
    <Overlay opacity={opacity}>{loaderElement}</Overlay>
  ) : (
    loaderElement
  );
};

export default LoaderComponent;
