import React from "react";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import CartAmountToggle from "../../ShopComponents/CartAmountToggle";
import { Link } from "react-router-dom";
import { pageTitle } from "../../../helpers/PageTitle";

import UserWallet from "./UserWallet";
import Offers from "./Offers";
import CreateOrder from "./CreateOrder";

export default function Marketplace() {
  pageTitle("Marketplace");
  return (
    <>
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Marketplace"
        subTitle="Shop"
        variant="text-center"
        href="/shop"
      />
      <Spacing lg="75" md="60" />

      <UserWallet />
      {/* <CreateOrder /> */}
      <Spacing lg="70" md="70" />

      <Offers />

      <Spacing lg="120" md="50" />
    </>
  );
}
