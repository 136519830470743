import React, { useState } from "react";
import styled from "styled-components";
import toast from "react-hot-toast";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 450px;
  background-color: #121212;
  z-index: 901;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;

  @media (max-width: 500px) {
    height: 500px;
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;

  span {
    color: #c09c5c;
  }
`;

const OriginalPrice = styled.p`
  font-size: 1rem;
  color: #14FFFF;
  margin-top: -20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background-color: #8c7444;
  }
`;

const TermsText = styled.p`
  font-size: 0.9rem;
  color: #fff;
  margin-top: 0px;
  text-align: center;
  margin-bottom: -20px;
`;

const TermsLink = styled.span`
  color: #2c65ff;
  cursor: pointer;
`;

const SellModal = ({ close, onSubmit, maxShares, sharePrice, projectName }) => {
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState(sharePrice);
  const [quantity, setQuantity] = useState("");

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setQuantity(value);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setAmount(value);
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setPrice(value);
    }
  };

  const handleSubmit = () => {
    if (amount && price && quantity && quantity <= maxShares) {
      onSubmit({ amount, price, quantity });
      close();
    } else {
      toast.error("Please fill in all fields and ensure quantity is within limit");
    }
  };

  return (
    <>
      <Overlay onClick={() => close()} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => close()}
        >
          X
        </div>
        <Header>
          Sell Shares of:<br></br><span>{projectName}</span>
        </Header>
        <OriginalPrice>Original price: <span style={{color:'#14FFFF'}}>${sharePrice}</span></OriginalPrice>
        <Input
          placeholder="Amount"
          value={amount}
          onChange={handleAmountChange}
          type="number"
          min="0"
        />
        <Input
          placeholder="Price per Share"
          value={price}
          onChange={handlePriceChange}
          type="number"
          min="0"
        />
        <Input
          placeholder={`Quantity (max ${maxShares})`}
          value={quantity}
          onChange={handleQuantityChange}
          type="number"
          min="0"
          max={maxShares}
        />
        <TermsText>
          <TermsLink>Terms and Conditions </TermsLink>apply to this purchase.
        </TermsText>
        <Button onClick={handleSubmit}>Submit</Button>
      </Popup>
    </>
  );
};

export default SellModal;