import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import LoaderComponent from "../components/Loader/Loader";

const ProtectedRoute = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const existingToken = localStorage.getItem("userToken"); // Token JWT

  useEffect(() => {
    if (!existingToken) {
      setIsAuthorized(false); // Redireciona se não estiver logado
      return;
    }

    // Chamada à API usando axios
    axios
      .get("https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${existingToken}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.accessLevel === 3) {
          setIsAuthorized(true); // Acesso autorizado se accessLevel for 3
        } else {
          setIsAuthorized(false); // Bloqueia acesso para outros níveis
        }
      })
      .catch((error) => {
        console.error("Erro ao verificar o nível de acesso:", error);
        setIsAuthorized(false); // Bloqueia acesso em caso de erro
      });
  }, [existingToken]);

  // Enquanto o status de autorização está sendo carregado
  if (isAuthorized === null) {
    return <LoaderComponent opacity={1} fullPage={true} />; // Mostra um loading enquanto verifica
  }

  // Redireciona para "/" se o usuário não estiver autorizado
  return isAuthorized ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
