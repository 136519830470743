import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import MarketingAgencyPage from "./components/Pages/MarketingAgencyPage";
import StudioAgencyPage from "./components/Pages/StudioAgencyPage";
import DigitalAgencyPage from "./components/Pages/DigitalAgencyPage";
import ServicePage from "./components/Pages/ServicePage";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogListPage from "./components/Pages/BlogListPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import CaseStudyDetailsPage from "./components/Pages/CaseStudyDetailsPage";
import TeamPage from "./components/Pages/TeamPage";
import TeamDetailsPage from "./components/Pages/TeamDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import Layout2 from "./components/Layout/Layout2";
import TechStartupPage from "./components/Pages/TechStartupPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import Shop from "./components/Pages/Shop/template";
import ProductDetails from "./components/Pages/Shop/template/ProductDetails";
import Cart from "./components/Pages/Shop/template/Cart";
import Checkout from "./components/Pages/Shop/template/Checkout";
import Success from "./components/Pages/Shop/template/Success";
import Wishlist from "./components/Pages/Shop/template/Wishlist";
import Layout3 from "./components/Layout/Layout3";
import ErrorPage from "./components/Pages/ErrorPage";

import Cadastro from "./components/Pages/Cadastro";
import Login from "./components/Pages/Login";
import ChangePassword from "./components/Pages/ChangePassword";
import Invest from "./components/Pages/Shop/Invest";
import Marketplace from "./components/Pages/Shop/Marketplace";
import Profile from "./components/Pages/Profile";
import Transactions from "./components/Pages/Shop/Transactions";
import CreateProject from "./components/Pages/Shop/CreateProject";
import ProtectedRoute from "./Routes/ProtectedRoute";
import ProtectedRouteCreateProject from "./Routes/Creat-Project";
import LoggedRoute from "./Routes/LoggedRout";
import CreateOrderPage from "./components/Pages/Shop/template/CreateOrderPage";
import Investments from "./components/Pages/investments/Investments";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout darkMode />}>
        <Route
          path="marketing-agency"
          element={<MarketingAgencyPage darkMode />}
        />
        <Route path="change-password" element={<ChangePassword darkMode />} />
        <Route path="project/:projectId" element={<Invest darkMode />} />
        <Route path="studio-agency" element={<StudioAgencyPage darkMode />} />
        <Route path="digital-agency" element={<DigitalAgencyPage darkMode />} />
        <Route path="about" element={<AboutPage darkMode />} />
        <Route path="service" element={<ServicePage />} />

        <Route
          path="login"
          element={
            <LoggedRoute>
              <Login darkMode />
            </LoggedRoute>
          }
        />
        <Route
          path="register"
          element={
            <LoggedRoute>
              <Cadastro darkMode />
            </LoggedRoute>
          }
        />

        <Route
          path="create-project"
          element={
            <ProtectedRouteCreateProject>
              <CreateProject darkMode />
            </ProtectedRouteCreateProject>
          }
        />

        <Route
          path="my-portfolio"
          element={
            <ProtectedRouteCreateProject>
              <Investments darkMode />
            </ProtectedRouteCreateProject>
          }
        />

        <Route
          path="create-order"
          element={
            <ProtectedRouteCreateProject>
              <CreateOrderPage darkMode />
            </ProtectedRouteCreateProject>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profile darkMode />
            </ProtectedRoute>
          }
        />
        <Route
          path="transactions"
          element={
            <ProtectedRoute>
              <Transactions darkMode />
            </ProtectedRoute>
          }
        />
        <Route
          path="marketplace"
          element={
            <ProtectedRoute>
              <Marketplace darkMode />
            </ProtectedRoute>
          }
        />

        <Route
          path="service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        />
        <Route path="blog" element={<BlogPage />} />
        <Route path="blog-list" element={<BlogListPage />} />
        <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route
          path="portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        />
        <Route path="case-study-details" element={<CaseStudyDetailsPage />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Route>
      <Route path="/" element={<Layout2 darkMode />}>
        {/* <Route index element={<Home />} /> */}
        <Route index element={<MarketingAgencyPage />} />
        <Route path="tech-startup" element={<TechStartupPage />} />
      </Route>
      <Route path="/" element={<Layout3 darkMode />}>
        <Route path="shop" element={<Shop />} />
        <Route path="shop/:productId" element={<ProductDetails />} />
        <Route path="shop/cart" element={<Cart />} />
        <Route path="shop/checkout" element={<Checkout />} />
        <Route path="shop/success" element={<Success />} />
        <Route path="shop/wishlist" element={<Wishlist />} />
      </Route>
      {/* Start Light Mode */}
      <Route path="/light/" element={<Layout />}>
        <Route path="marketing-agency" element={<MarketingAgencyPage />} />
        <Route path="studio-agency" element={<StudioAgencyPage />} />
        <Route path="digital-agency" element={<DigitalAgencyPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="service" element={<ServicePage />} />
        <Route
          path="service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        />
        <Route path="blog" element={<BlogPage />} />
        <Route path="blog-list" element={<BlogListPage />} />
        <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route
          path="portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        />
        <Route path="case-study-details" element={<CaseStudyDetailsPage />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Route>
      <Route path="/light/" element={<Layout2 />}>
        <Route index element={<Home />} />
        <Route path="tech-startup" element={<TechStartupPage />} />
      </Route>
      <Route path="/light/" element={<Layout3 />}>
        <Route path="shop" element={<Shop />} />
        <Route path="shop/:productId" element={<ProductDetails />} />
        <Route path="shop/cart" element={<Cart />} />
        <Route path="shop/checkout" element={<Checkout />} />
        <Route path="shop/success" element={<Success />} />
        <Route path="shop/wishlist" element={<Wishlist />} />
      </Route>
      {/* End Light Mode */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
